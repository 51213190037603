<template>
	<component :is="activeComponent" />
</template>

<script>
	import { defineComponent, computed, watch, inject, defineAsyncComponent, onMounted } from 'vue'
	import { useRoute } from 'vue-router'
	import { useFlowControlsStore } from '@/stores/flowControls'
	import { requestIdleCallback, sendAnalytics } from '@/plugins/global'

	export default defineComponent({
		name: 'HomeView',
		setup() {
			const route = useRoute()
			const cookies = inject('cookies')
			const stanAnalytics = inject('stanAnalytics')

			const fcStore = useFlowControlsStore()
			const fcpComplete = computed(() => fcStore.fcpComplete)
			const vueGtagInstall = computed(() => fcStore.vueGtagInstall)

			const landingPageComponents = {
				6: defineAsyncComponent(() => import('@/components/home/LandingPageV6.vue')),
			}
			const landingPageVersions = Object.keys(landingPageComponents)
			const pageVersion = computed(() => {
				const pv =
					route.query.version ||
					cookies.get('landingVersion') ||
					landingPageVersions[Math.floor(Math.random() * landingPageVersions.length)]
				return !pv || !landingPageComponents[pv] ? landingPageVersions[0] : pv
			})
			cookies.set('landingVersion', pageVersion.value, '1d')
			cookies.set('landingVersion', pageVersion.value, '1d', null, 'stanwith.me')
			cookies.set('landingVersion', pageVersion.value, '1d', null, 'stan.store')
			document.body.classList.add(`page-version-${pageVersion.value}`)
			document.documentElement.classList.add(`page-version-${pageVersion.value}`)

			if (pageVersion.value === '2') {
				cookies.set('platform_subscription_priceId', process.env.VUE_APP_STAN_EXPERIMENT_PRICE_ID, '1d')
				cookies.set('platform_subscription_priceId', process.env.VUE_APP_STAN_EXPERIMENT_PRICE_ID, '1d', null, 'stanwith.me')
				cookies.set('platform_subscription_priceId', process.env.VUE_APP_STAN_EXPERIMENT_PRICE_ID, '1d', null, 'stan.store')

				cookies.set('platform_subscription_price', process.env.VUE_APP_STAN_EXPERIMENT_PRICE, '1d')
				cookies.set('platform_subscription_price', process.env.VUE_APP_STAN_EXPERIMENT_PRICE, '1d', null, 'stanwith.me')
				cookies.set('platform_subscription_price', process.env.VUE_APP_STAN_EXPERIMENT_PRICE, '1d', null, 'stan.store')
			} else {
				cookies.set('platform_subscription_priceId', '', '-1')
				cookies.set('platform_subscription_priceId', '', '-1', null, 'stanwith.me')
				cookies.set('platform_subscription_priceId', '', '-1', null, 'stan.store')

				cookies.set('platform_subscription_price', '', '-1')
				cookies.set('platform_subscription_price', '', '-1', null, 'stanwith.me')
				cookies.set('platform_subscription_price', '', '-1', null, 'stan.store')
			}

			const activeComponent = computed(() => {
				return landingPageComponents[pageVersion.value]
			})

			watch(
				() => vueGtagInstall.value,
				vt => {
					if (vt) {
						requestIdleCallback(() => {
							sendAnalytics(route, cookies, stanAnalytics, pageVersion.value)
						})
					}
				}
			)

			return {
				activeComponent,
			}
		},
	})
</script>

<style lang="scss">
	.video-wrapper {
		position: relative;
		padding: 10px 10px;
		width: 100%;
		border-radius: 20px;

		.fade-enter-active,
		.fade-leave-active {
			transition: opacity 0.5s;
		}

		.fade-enter,
		.fade-leave-to {
			opacity: 0;
		}

		video {
			position: relative;
			width: 100%;
			border-radius: 20px;
			justify-content: center;
			align-items: center;
			padding: 0 0;
		}
	}

	.artplayer-app {
		background-color: transparent !important;
		border: 1px solid #000000;

		.art-video-player {
			background-color: transparent !important;

			.art-poster {
				border-radius: 20px !important;
			}

			.art-bottom {
				background: none;
			}
		}
	}

	.format-desktop {
		&.video-wrapper {
			width: 600px;
			height: 337px;
		}

		.artplayer-app {
			width: 600px;
			height: 337px;
		}
	}

	.format-mobile {
		&.video-wrapper {
			width: 343px;
			height: 610px;
		}

		.artplayer-app {
			width: 243px;
			height: 410px;
		}
	}
</style>
